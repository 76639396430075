<template>
  <div style="overflow-x:clip; padding:0 10px;" v-swiper:EventsSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center top-0 events-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'EventsSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        autoHeight: true,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        watchOverflow: true,
        noSwiping: false,
        pagination: {
          clickable: true,
          el: '.events-pagination',
        },
        allowTouchMove: true,
        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 80,
          }
        }
      }
    }
  }
}
</script>
