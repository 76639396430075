<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.207" height="9.414" viewBox="0 0 16.207 9.414" :class="$props.position">
    <line fill="none" stroke="currentColor" stroke-linecap="round" x1="15.5" y1="4.7" x2=".5" y2="4.7"/>
    <line fill="none" stroke="currentColor" stroke-linecap="round" x1="4.5" y1="8.7" x2=".5" y2="4.7"/>
    <line fill="none" stroke="currentColor" stroke-linecap="round" x1="4.5" y1=".7" x2=".5" y2="4.7"/>
  </svg>
</template>
<script>
export default {
  name: 'ButtonLeft',
  props: ['position'],
}
</script>
