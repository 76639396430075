<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }">
    <div v-if="success" class="mb-8 border-2 text-white border-white bg-primary font-lato p-4" role="alert">
      <div class="flex">
        <div class="py-1">
          <svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
          </svg>
        </div>
        <div>
          <p class="font-bold">Thank You!</p>
          <p class="text-sm">{{ success }}</p>
        </div>
      </div>
    </div>
    <div v-if="error" class="mb-8 border-2 text-white border-white font-lato bg-primary p-4" role="alert">
      <div class="flex">
        <div class="py-1">
          <svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
          </svg>
        </div>
        <div>
          <p class="font-bold">Oops!</p>
          <p class="text-sm">{{ error }}</p>
        </div>
      </div>
    </div>
    <form name="contact-form" class="grid gap-x-6 gap-y-6 text-left md:grid-cols-4" @submit.stop>
      <validation-provider v-slot="{ errors }" name="Service" class="md:col-span-4" rules="required">
        <div>
          <div class="text-left text-secondary text-base w-full font-bold">
            Select which service you are interested in
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-3 mt-3">
            <template v-for="(service, index) in services">
              <label class="text-secondary inline-flex items-center radio-group" :for="`service-${index}`">
                <input type="radio"
                       name="service"
                       :id="`service-${index}`"
                       v-model="form.service"
                       :checked="form.service === service"
                       :value="service"
                />
                <span>{{ service }}</span>
              </label>
            </template>
          </div>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="First Name" class="md:col-span-2" rules="required">
        <div>
          <label for="firstname" class="text-left text-secondary text-base w-full">First Name</label>
          <input
              id="form-firstname"
              v-model="form.firstname"
              :class="{ error: errors[0] }"
              type="text"
              class="border-b py-2 px-1 border-secondary w-full"
          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Last Name" class="md:col-span-2" rules="required">
        <div>
          <label for="lastname" class="text-left text-secondary text-base w-full">Last Name</label>
          <input
              id="form-lastname"
              v-model="form.lastname"
              :class="{ error: errors[0] }"
              type="text"
              class="border-b py-2 px-1 border-secondary w-full"
          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Email" class="md:col-span-4" rules="required">
        <div>
          <label for="lastname" class="text-left text-secondary text-base w-full">Email</label>
          <input
              id="form-email"
              v-model="form.email"
              :class="{ error: errors[0] }"
              type="text"
              class="border-b py-2 px-1 border-secondary w-full"

          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Message" class="md:col-span-4" rules="required">
        <div>
          <label for="lastname" class="text-left text-secondary text-base w-full">How can we help you?</label>
          <textarea
              id="form-message"
              v-model="form.message"
              :class="{ error: errors[0] }"
              class="border-b py-2 px-1 border-secondary w-full"

              rows="4"
          ></textarea>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <div class="md:col-span-4">
        <button :disabled="invalid" class="solid-button" @click.prevent="passes(SendMessage)">
          Send
          <button-right position="last"></button-right>
        </button>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      services: {
        'property-finding': 'Property Finding',
        'sell-my-property': 'Sell My Property',
        'expat-services': 'Expat Services',
        'academy': 'NI Property Academy',
      },
      form: {
        service: null,
        firstname: null,
        lastname: null,
        email: null,
        message: null,
      }
    }
  },
  mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      const service = urlParams.get('service');
      if(service.length && this.services[service] !== undefined) {
        this.form.service = this.services[service];
      }
  },
  methods: {
    Reset() {
      this.form = {
        service: null,
        firstname: null,
        lastname: null,
        email: null,
        message: null,
      };
    },
    SendMessage() {
      this.loading = true
      this.$axios.post('/wp-json/icon/v1/contact', this.form)
          .then((response) => {
            this.form = {};
            this.$nextTick(() => this.$refs.form.reset());
            this.success = response.data.message;
            this.error = false;
          })
          .catch((err) => {
            this.success = false;
            this.error = err.response.data.message;
          })
          .then(() => {
            this.loading = false;
          })
    }
  }
}
</script>
<style>
input:active {
  border: none !important;
}

input:focus-visible {
  outline-width: 0px;
}

textarea:focus-visible {
  outline-width: 0px;
}
</style>
