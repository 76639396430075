<template>
  <div v-swiper:VideoSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center top-0 video-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'VideoSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        loop: false,
        spaceBetween: 40,
        pagination: {
          clickable: true,
          el: '.video-pagination',
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
          }
        }
      }
    }
  }
}
</script>
