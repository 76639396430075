<template>
  <div>
    <div v-swiper:AcademySlider="settings" :ref="selector">

      <div class="swiper-wrapper">
        <slot/>
      </div>
    </div>
    <div class="pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'AcademySlider',
  props: {
    selector: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        direction: "horizontal",
        slidesPerView: 1,
        loop: false,
        spaceBetween: 30,
        watchOverflow: true,
        noSwiping: false,
        freeMode: true,
        pagination: {
          clickable: true,
          el: `#${this.$props.selector} .pagination`,
        },
        allowTouchMove: true,
        breakpoints: {
          765: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          810: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1080: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 3.5,
            spaceBetween: 40,
          },
        }
      }
    }
  }
}
</script>
