<template>
  <div style="overflow-x:clip; padding:0 10px;" v-swiper:ReviewSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center top-0 review-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'ReviewSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        autoHeight: true,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        watchOverflow: true,
        autoplay: {
          delay: 5000,
        },
        noSwiping: false,
        pagination: {
          clickable: true,
          el: '.review-pagination',
        },
        allowTouchMove: true,
        breakpoints: {
          992: {
            slidesPerView: 1,
            spaceBetween: 30,
          }
        }
      }
    }
  }
}
</script>
