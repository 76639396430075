import Vue from 'vue'
window.Vue = Vue
import VueAnimate from 'vue-animate-scroll'
Vue.use(VueAnimate)
import axios from 'axios'
import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import VuePlyr from 'vue-plyr';
Vue.use(VuePlyr);

import AppFixedHeader from './components/AppFixedHeader'
import ReviewSlider from './components/ReviewSlider'
import NewsSlider from './components/NewsSlider'
import StaffSlider from './components/StaffSlider'
import ProjectSlider from './components/ProjectSlider'
import AppNavBar from './components/AppNavBar'
import ScrollTop from './components/ScrollTop'
import ContactForm from './components/ContactForm'
import AcademySlider from './components/AcademySlider'
import VideoSlider from './components/VideoSlider'
import EventsSlider from './components/EventsSlider'
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)
Vue.component('ScrollTop', ScrollTop)
Vue.component('ReviewSlider', ReviewSlider)
Vue.component('NewsSlider', NewsSlider)
Vue.component('StaffSlider', StaffSlider)
Vue.component('ProjectSlider', ProjectSlider)
Vue.component('AcademySlider', AcademySlider)
Vue.component('VideoSlider', VideoSlider)
Vue.component('ContactForm', ContactForm)
Vue.component('EventsSlider', EventsSlider)

const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.prototype.$axios = axios
Vue.config.productionTip = false

extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})
$(document).ready(function(){
	$('#nav-icon4').click(function(){
		$(this).toggleClass('open');
	});
  $('.accordion-list > li > .answer').hide();

  $('.accordion-list > li > .clickavle').click(function() {
    if ($(this).parent().hasClass("active")) {
      $(this).parent().removeClass("active").find(".answer").slideUp();
    } else {
      $(".accordion-list > li.active .answer").slideUp();
      $(".accordion-list > li.active").removeClass("active");
      $(this).parent().addClass("active").find(".answer").slideDown();
    }
    return false;
  });
});

// iOS double click fix - https://stackoverflow.com/a/76245027
document.addEventListener("touchstart", function() {},false);
