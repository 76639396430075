<template>
  <div v-swiper:ProjectSlider="settings" class="overflow-x-hidden">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center project-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'ProjectSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        pagination: {
          clickable: true,
          el: '.project-pagination',
        },
        watchOverflow: true,
        allowTouchMove: true,
        breakpoints: {
          765: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        }
      }
    }
  }
}
</script>
