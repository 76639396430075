<template>
  <div id="nav">
    <div class="border-nav w-screen overflow-hidden relative z-50">
      <div class="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto px-4 md:px-8 lg:px-12 2xl:px-8 py-4 lg:py-8">
        <div class="md:w-full relative flex justify-between items-center lg:gap-x-12">
          <div class="relative mb-0">
            <slot name="brand"/>
          </div>
          <div
              class="slide-menu  hidden lg:flex items-center gap-x-8 justify-end md:w-full text-white font-headline text-base">
            <slot name="profile"/>
            <button name="main menu" aria-label="main menu" type="button"
                    @click="toggle"
                    class="text-center text-primary font-lato text-sm uppercase tracking-widest">
              <div id="nav-icon4">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
          <div class="mobile-menu h-6 lg:hidden">
            <slot name="profile-mobile"/>
            <button name="main menu" aria-label="main menu" type="button"
                    @click="toggle"
                    class="text-center text-primary font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div :class="{ desktopmenu: isActive }"
         class="absolute overflow-hidden z-40 main-show w-screen hidden lg:block h-fit bg-purple bg-cover bg-no-repeat top-0 right-0 left-0 bottom-0 invisi">
      <div
          class="max-w-screen-xl h-full flex 2xl:max-w-screen-2xl relative mx-auto px-4 lg:px-8 py-4 lg:py-12 items-center">
        <div class="wrapper">
          <slot name="mainmenu"/>
        </div>
      </div>
    </div>
    <div :class="{ mobilemenu: isActive }"
         class="absolute w-screen block lg:hidden h-screen bg-purple bg-cover bg-no-repeat top-0 right-0 left-0 bottom-0 invisib">
      <div class="h-full relative overflow-x-hidden pb-12">
        <div class="px-4 text-center h-screen flex items-center justify-center">
          <div class="wrapper mobile-wrapper">
            <transition
                enter-class="translate-x-full"
                enter-active-class="ease-out transition-slow"
                enter-to-class="translate-x-0"
                leave-class="translate-x-0"
                leave-active-class="ease-in"
                leave-to-class="translate-x-full"
                appear
            >
              <div v-if="!mobileSection">
                <ul>
                  <li class="menu-item"><span @click.prevent="openChild('nipg')">NI Property Girl</span></li>
                  <li class="menu-item"><span @click.prevent="openChild('academy')">NI Property Academy</span></li>
                </ul>
                <slot name="mobile-menu-other"/>

                <div class="mt-12">
                  <a href="/contact/?service=nipg"
                     class="text-white border-2 border-primary rounded-full px-6 py-2.5 font-semibold text-xl w-fit text-white;">
                    Contact Us
                  </a>
                </div>
              </div>
            </transition>

            <transition
                enter-class="translate-x-full"
                enter-active-class="ease-out transition-slow"
                enter-to-class="translate-x-0"
                leave-class="translate-x-0"
                leave-active-class="ease-in"
                leave-to-class="translate-x-full"
                appear
            >
              <div v-if="mobileSection === 'nipg'">
                <div class="flex flex-row mb-6 items-center">
                <span @click="closeChild" class="text-white cursor-pointer">
                  <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 29L2 15L16 0.999999" stroke="currentColor" stroke-width="1.5"/>
                  </svg>
                </span>
                  <p class="text-[24px] text-primary w-full px-6 text-center">NI Property Girl</p>
                </div>
                <slot name="mobile-menu-nipg"/>

                <div class="mt-12">
                  <a href="/contact/?service=nipg"
                     class="text-white border-2 border-primary rounded-full px-6 py-2.5 font-semibold text-xl w-fit text-white;">
                    Contact Us
                  </a>
                </div>
              </div>
            </transition>

            <transition
                enter-class="translate-x-full"
                enter-active-class="ease-out transition-slow"
                enter-to-class="translate-x-0"
                leave-class="translate-x-0"
                leave-active-class="ease-in"
                leave-to-class="translate-x-full"
                appear
            >
              <div v-if="mobileSection === 'academy'">
                <div class="flex flex-row mb-6 items-center">
              <span @click="closeChild" class="text-white cursor-pointer">
                  <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 29L2 15L16 0.999999" stroke="currentColor" stroke-width="1.5"/>
                  </svg>
                </span>
                  <p class="text-[24px] text-primary w-full px-6 text-center">NI Property Academy</p>
                </div>
                <slot name="mobile-menu-academy"/>

                <div class="mt-12">
                  <a href="/contact/?service=nipg"
                     class="text-white border-2 border-primary rounded-full px-6 py-2.5 font-semibold text-xl w-fit text-white;">
                    Contact Academy
                  </a>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      services: false,
      isActive: false,
      mobileSection: null,
    }
  },
  methods: {
    toggle: function () {
      this.isActive = !this.isActive;

      setTimeout(function() {
        document.querySelector('body').classList.toggle('nav-open');
      }, 200);

      document.querySelector('body').classList.toggle('overflow-hidden');
      document.querySelector('body').classList.toggle('max-h-screen');

      if (!this.isActive) {
        this.closeChild();
      }
    },
    openChild: function (child) {
      this.mobileSection = 1;

      setTimeout(() => {
        this.mobileSection = child;
      }, 150);
    },
    closeChild: function () {
      this.mobileSection = 1;

      setTimeout(() => {
        this.mobileSection = null;
      }, 150);
    }
  }
}
</script>
<style lang="scss">
.invisi {
  margin-top: -105vh;
  transition: ease-in-out 0.5s all;
}

.invisit {
  margin-top: -105vh;
  transition: ease-in-out 0.5s all;
}

.invisib {
  margin-top: -200vh;
  transition: ease-in-out 0.5s all;
}

.desktopmenu {
  margin-top: 0;
  background-image: url('/app/themes/wordpress/assets/images/new-menu-bg.jpg');
  background-position: center center;
}

.mobilemenu {
  margin-top: 0;
  background-image: url('/app/themes/wordpress/assets/images/new-menu-mobile-bg.jpg');
  background-position: center center;
}

.slideit {
  animation: slideit 1.5s;
}

@keyframes slideit {
  0% {
    margin-top: -105vh;
  }
  30% {
    margin-top: 0;
  }
  70% {
    margin-top: 0;
  }
  100% {
    margin-top: 105vh;
  }
}
.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.transition-slow {
  transition-duration: 300ms
}

.translate-x-full {
  transform: translateX(200%);
}

.translate-x-0 {
  transform: translateX(0);
}
</style>
