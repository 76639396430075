<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.207" height="9.414" viewBox="0 0 16.207 9.414" :class="$props.position">
    <g transform="translate(0.5 0.707)">
      <line x2="15" transform="translate(0 4)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1"></line>
      <line x2="4" y2="4" transform="translate(11)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1"></line>
      <line y1="4" x2="4" transform="translate(11 4)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1"></line>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ButtonRight',
  props: ['position'],
}
</script>
